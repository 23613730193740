
import { computed, defineComponent, ref, onMounted, getCurrentInstance, onBeforeMount } from "vue";

import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { useRoute, useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import Multiselect from "@vueform/multiselect";
import MasterService from "@/core/services/car/MasterService";
import { notif } from "@/store/stateless/store";

interface EditRefundPolicyBodyParam {
  policy_id: any | null;
  refund_type: any | null;
  refund_reasons: any | null;
  refund_status: any | null;
  refund_payable_amount_customer: any | null;
  refund_payable_amount_agent: any | null;
  refund_note: any | null;
}
interface PolicyBodyParam {
  per_page: string;
  status: any;
  keyword: string;
  start_date: string | null;
  end_date: string | null;
  agent: string;
  policy_sales_agent: any;
  underwriter: any;
  payment_type: any;
}
export default defineComponent({
  name: "refund-request-document-modal",
  components: { Multiselect },
  props: {
    policyId: Number,
    policyObj: { type: Object, required: true },
    cancellationDocs: Array,
    status: Number,
    tempDocs: Array,
  },
  setup(props,context) {
    const store = useStore();
    const refundPolicyReqModalRef = ref<null | HTMLElement>(null);
    const inst = getCurrentInstance()

    let isFormValid = ref<boolean>(true);
    let refund_payable_amount = ref<any>(0);
    let editRefundPolicyBodyParam = ref<EditRefundPolicyBodyParam>({
      policy_id: props.policyObj?.id,
      refund_type: "",
      refund_reasons: "",
      refund_status: 1,
      refund_payable_amount_customer: 0,
      refund_payable_amount_agent: 0,
      refund_note: "",
    });
    let bodyParameters = ref<PolicyBodyParam>({
      status: props.status,
      per_page: "50",
      keyword: "",
      start_date: "",
      end_date: "",
      agent: "all",
      policy_sales_agent: "",
      underwriter: "",
      payment_type: "",
    });
    let policyDetails = computed(() => {
      return store.getters.getpolicyDetails;
    });

    onMounted(() => {
      // MasterService.getReasonnReason();
      PolicyService.resetPolicyError();
    })

    const getPolicyList = (bodyParameters, reload = true) => {
      // bodyParameters.status =
      //   bodyParameters.status == 0 ? "" : bodyParameters.status;
    bodyParameters.value.reload = reload
      Promise.all([
        PolicyService.getPolicyList(bodyParameters.value),
        PolicyService.getPolicyListCount(bodyParameters.value),
      ]).then((data) => {
        // policyCount.value = store.getters.getPolicyCount;
      });
    };
    const reqRefundRules = ref({
      refund_type: {
        required: true,
        message: "Please select refund type",
        show: false,
      },
      refund_reason: {
        required: true,
        message: "Please select refund reason",
        show: false,
      },
      payable_amount: {
        required: true,
        message: "Please enter payable amount",
        show: false,
      },
      agent_notes: {
        required: true,
        message: "Please enter agent notes",
        show: false,
      },
    });
    const resetValidation = () => {
      reqRefundRules.value.refund_type.show = false;
      reqRefundRules.value.refund_reason.show = false;
      reqRefundRules.value.payable_amount.show = false;
      reqRefundRules.value.agent_notes.show = false;
      editRefundPolicyBodyParam.value.refund_payable_amount_agent = 0;
      editRefundPolicyBodyParam.value.refund_payable_amount_customer = 0;
      isDecimalValid.value = true;
      
    };

    const closeRefundRequest = () => {
      resetValidation() 
      resetRefundRequest();
      PolicyService.resetPolicyRefundError();
    }
    const validateCancellationForm = () => {
      // console.log(editRefundPolicyBodyParam.value);
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;

      if(!decimalPlaces.test(String(editRefundPolicyBodyParam.value.refund_payable_amount_agent))) {
        reqRefundRules.value.payable_amount.message = 'Please enter upto 2 decimal places only';
        reqRefundRules.value.payable_amount.show = true;
        isFormValid.value = false;
        isDecimalValid.value = false
      }
      if(!decimalPlaces.test(String(editRefundPolicyBodyParam.value.refund_payable_amount_customer))) {
        reqRefundRules.value.payable_amount.message = 'Please enter upto 2 decimal places only';
        reqRefundRules.value.payable_amount.show = true;
        isFormValid.value = false;
        isDecimalValid.value = false
      }

      if (editRefundPolicyBodyParam.value.refund_type == "") {
        reqRefundRules.value.refund_type.show = true;
        isFormValid.value = false;
      }
      if (editRefundPolicyBodyParam.value.refund_reasons == "") {
        reqRefundRules.value.refund_reason.show = true;
        isFormValid.value = false;
      }
      if (
        editRefundPolicyBodyParam.value.refund_payable_amount_customer == 0 &&
        editRefundPolicyBodyParam.value.refund_payable_amount_agent == 0
      ) {
        reqRefundRules.value.payable_amount.show = true;
        isFormValid.value = false;
      }
      if (
        editRefundPolicyBodyParam.value.refund_payable_amount_customer >
        policyDetails.value.car_lead_invoice.sale_price
      ) {
        reqRefundRules.value.payable_amount.show = true;
        reqRefundRules.value.payable_amount.message =
          "Payable amount, cannot be greater than the invoice amount";
        isFormValid.value = false;
      }
      if (editRefundPolicyBodyParam.value.refund_note == "") {
        reqRefundRules.value.agent_notes.show = true;
        isFormValid.value = false;
      }
    };

    const refundReasonList = computed(() => {
      return store.getters.getRefundReasons;
    });
    const refundReason = ref({
      placeholder: "Please Select Refund Reason",
      value: null,
      options: refundReasonList,
      searchable: true,
      clearOnSelect: true,
    });

    const onChangeRefundReason = (event) => {
      reqRefundRules.value.refund_reason.show = false
      editRefundPolicyBodyParam.value.refund_reasons = event;
    };
    const policyRefundRequest = () => {
      PolicyService.resetPolicyError();
      isFormValid.value = true;

      resetValidation();

      editRefundPolicyBodyParam.value.policy_id = props.policyId;

      if (editRefundPolicyBodyParam.value.refund_type == "commision-paid") {
        editRefundPolicyBodyParam.value.refund_payable_amount_agent =
          refund_payable_amount.value;
      } else {
        editRefundPolicyBodyParam.value.refund_payable_amount_customer =
          refund_payable_amount.value;
      }

      validateCancellationForm();
      // console.log(editRefundPolicyBodyParam.value);
      if (isFormValid.value) {
        Promise.all([
          PolicyService.policyRefundRequest(editRefundPolicyBodyParam.value),
        ]).then((data) => {
          resetRefundRequest();
          context.emit("callPolicyDetails");
          notif.simple("Policy Refund", "success", "You have successfully Initiated the policy refund!");
          
          hideModal(refundPolicyReqModalRef.value);
          getPolicyList(bodyParameters, false);
        });
      } else {
        focusField()
      }
    };

    const resetRefundRequest = () => {
      editRefundPolicyBodyParam.value.policy_id ="";
      editRefundPolicyBodyParam.value.refund_type ="";
      editRefundPolicyBodyParam.value.refund_reasons ="";
      editRefundPolicyBodyParam.value.refund_status ="1";
      editRefundPolicyBodyParam.value.refund_payable_amount_customer= 0,
      editRefundPolicyBodyParam.value.refund_payable_amount_agent =0;
      editRefundPolicyBodyParam.value.refund_note = "";

      refundReason.value.value = null;
      refund_payable_amount.value = 0;
  
    }
    const showDomLoad = computed(() => {
      return store.getters.getPolicyDomStatus;
    });
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const policyError = computed(() => {
      return store.getters.getPolicyErrors;
    })

    const totalRefundPayableAmountCustomer = computed(() => {
      const data = store.getters.getpolicyDetails
      if(data.car_refund_completed) {
        return data.car_refund_completed.reduce((total: any, item: any) => {
          return total += parseFloat(item.refund_payable_amount_customer)
        }, 0)
      } else {
        return 0;
      }
    })
    const totalRefundPayableAmountAgent = computed(() => {
      const data = store.getters.getpolicyDetails
      if(data.car_refund_completed) {
        return data.car_refund_completed.reduce((total: any, item: any) => {
          return total += parseFloat(item.refund_payable_amount_agent)
        }, 0)
      } else {
        return 0;
      }
      })

      const totalRefund = computed(() => {
        return parseFloat(totalRefundPayableAmountCustomer.value) + parseFloat(totalRefundPayableAmountAgent.value)
      })

      const refundError = computed(() => {
        return store.getters.getEditPolicyRefundError;
      })

      const resetPayableAmount = () => {
        reqRefundRules.value.payable_amount.show = false;
        isDecimalValid.value = true;
        PolicyService.resetPolicyRefundError();
      }

      const isDecimalValid = ref(true);

      const focusField = () => {
        const { refund_type, refund_reason, payable_amount, agent_notes } = reqRefundRules.value

        if(refund_type.show) {
          const refundTypeRef:any = inst?.refs?.refundTypeRef
          refundTypeRef.focus()
        }

        if(!refund_type.show && refund_reason.show) {
          const refundReasonRef:any = inst?.refs?.refundReasonRef
          refundReasonRef.focusInput()
        }

        if(!refund_type.show && !refund_reason.show && payable_amount.show) {
          const refundPayAmountRef:any = inst?.refs?.refundPayAmountRef
          refundPayAmountRef.isFocus()
        }

        if(!refund_type.show && !refund_reason.show && !payable_amount.show && agent_notes.show) {
          const refundNotedRef:any = inst?.refs?.refundNotedRef
          refundNotedRef.focus()
        }
      }

      const toFirst = () => {
        setTimeout(() => {
          const refundTypeRef:any = inst?.refs?.refundTypeRef
          const refundTypePartialRef:any = inst?.refs?.refundTypePartialRef
          if(refundTypeRef.disabled && refundTypeRef.disabled === "") {
            refundTypeRef.focus()
          } else {
            refundTypePartialRef.focus()
          }
        }, 1000);
      }


      const cancelButton =ref()
    const closeModal = (e) => {
      if(e.which === 27) {
        cancelButton.value.click()
      }
    }

      onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      cancelButton,
      closeModal,
      toFirst,
      focusField,
      refundError,
      isDecimalValid,
      closeRefundRequest,
      resetValidation,
      totalRefund,
      totalRefundPayableAmountCustomer,
      totalRefundPayableAmountAgent,
      policyError,
      refundPolicyReqModalRef,
      policyDetails,
      editRefundPolicyBodyParam,
      refundReason,
      policyRefundRequest,
      onChangeRefundReason,
      refund_payable_amount,
      showDomLoad,
      disableSaveButton,
      reqRefundRules,
      resetPayableAmount,
    };
  },
});
