
import { computed, defineComponent, ref, onMounted, getCurrentInstance, onBeforeMount } from "vue";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { useRoute, useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import DropzoneFile from "@/views/car/policy/DropZone.vue";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
import { notif } from "@/store/stateless/store";
interface CancelPolicyBodyParam {
  policy_id: any | null;
  cancellation_type: any | null;
  cancellation_reason: any | null;
  cancellation_note: any | null;
  documents: any | null;
}
interface PolicyBodyParam {
  per_page: string;
  status: any;
  keyword: string;
  start_date: string | null;
  end_date: string | null;
  agent: string;
  policy_sales_agent: any;
  underwriter: any;
  payment_type: any;
}
export default defineComponent({
  name: "cancel-document-modal",
  components: {
    Multiselect,
    DropzoneFile,
    ViewTempDocuments,
  },
  props: {
    policyId: Number,
    policyObj: Object,
    tempDocs: Array,
    status: Number,
  },
  setup(props, context) {
    const store = useStore();
    const cancelPolicyReqModalRef = ref<null | HTMLElement>(null);
    const inst = getCurrentInstance()
    let isFormValid = ref<boolean>(true);
    let cancellationDocuments = ref<any>();
    let cancelPolicyBodyParam = ref<CancelPolicyBodyParam>({
      policy_id: null,
      cancellation_type: null,
      cancellation_reason: null,
      cancellation_note: null,
      documents: [],
    });
    let bodyParameters = ref<PolicyBodyParam>({
      status: props.status,
      per_page: "50",
      keyword: "",
      start_date: "",
      end_date: "",
      agent: "all",
      policy_sales_agent: "",
      underwriter: "",
      payment_type: "",
    });
    let userObj = computed(() => {
      return store.getters.currentUser;
    });
    let policyDetails = computed(() => {
      return store.getters.getpolicyDetails;
    });
    let cancellationReasonList = computed(() => {
      return store.getters.getCancellationReasons;
    });
    const cancellationReason = ref({
      placeholder: "Please Select Cancellation Reason",
      value: null,
      options: cancellationReasonList,
      searchable: true,
      clearOnSelect: true,
    });
    const onChangeCancellationReason = (event) => {
      reqCancellationRules.value.cancellation_reason.show = false
      cancelPolicyBodyParam.value.cancellation_reason = event;
    };
    const reqCancellationRules = ref({
      cancellation_type: {
        required: true,
        message: "Please select cancellation type",
        show: false,
      },
      cancellation_reason: {
        required: true,
        message: "Please select cancellation reason",
        show: false,
      },
      cancellation_note: {
        required: true,
        message: "Please enter agent cancellation note",
        show: false,
      },
      documents: {
        required: true,
        message: "Please upload document",
        show: false,
      },
    });
    const validateCancellationForm = () => {
      // console.log(cancelPolicyBodyParam.value);
      if (
        cancelPolicyBodyParam.value.cancellation_note == "" ||
        cancelPolicyBodyParam.value.cancellation_note == null
      ) {
        reqCancellationRules.value.cancellation_note.show = true;
        isFormValid.value = false;
      }
      if (
        cancelPolicyBodyParam.value.cancellation_reason == "" ||
        cancelPolicyBodyParam.value.cancellation_reason == null
      ) {
        reqCancellationRules.value.cancellation_reason.show = true;
        isFormValid.value = false;
      }
      if (
        cancelPolicyBodyParam.value.cancellation_type == "" ||
        cancelPolicyBodyParam.value.cancellation_type == null
      ) {
        reqCancellationRules.value.cancellation_type.show = true;
        isFormValid.value = false;
      }

      // if(cancelPolicyBodyParam.value.documents.length == 0) {
      //   reqCancellationRules.value.documents.show = true;
      //   isFormValid.value = false;
      // }
    };
    const getPolicyList = (bodyParameters) => {
      bodyParameters.value.agent = userObj.value.role_id == 1 ? "all" : "mine";
        bodyParameters.value.reload = false
      Promise.all([
        PolicyService.getPolicyList(bodyParameters.value),
        PolicyService.getPolicyListCount(bodyParameters.value),
      ]).then((data) => {
        // policyCount.value = store.getters.getPolicyCount;
      });
    };

    const buttonLoading = ref(false);
    const cancelPolicy = () => {

      buttonLoading.value = true;

      isFormValid.value = true;
      cancelPolicyBodyParam.value.documents = [];
      cancelPolicyBodyParam.value.policy_id = props.policyId;
      cancellationDocuments.value = store.getters.getTempPolicyDocs;

      cancellationDocuments.value.forEach((doc) => {
        let document = {
          document_id: doc.id,
          type: doc.type,
        };
        cancelPolicyBodyParam.value.documents.push(document);
      });
      validateCancellationForm();
      if (isFormValid.value) {
        Promise.all([
          PolicyService.cancelPolicyRequest(cancelPolicyBodyParam.value),
        ])
          .then((data) => {
            buttonLoading.value = false;
            cancelPolicyBodyParam.value = {
              policy_id: null,
              cancellation_type: null,
              cancellation_reason: null,
              cancellation_note: null,
              documents: [],
            };
            cancellationReason.value.value = null;
            context.emit("callPolicyDetails");
            notif.simple("Policy Cancellation", "success", "You have successfully Initiated the policy cancellation!");
            hideModal(cancelPolicyReqModalRef.value);
            getPolicyList(bodyParameters);
          })
          .catch(() => {
            buttonLoading.value = false;
          });
      } else {
        focusField()
        buttonLoading.value = false;
      }
    };
    const showDomLoad = computed(() => {
      return store.getters.getPolicyDomStatus;
    });
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const focusField = () => {
      const { cancellation_type, cancellation_reason, cancellation_note } = reqCancellationRules.value

      if(cancellation_type.show) {
        const cancellationTypeRef:any = inst?.refs?.cancellationTypeRef
        cancellationTypeRef.focus()
      }

      if(!cancellation_type.show && cancellation_reason.show) {
        const cancellationReasonRef:any = inst?.refs?.cancellationReasonRef
        cancellationReasonRef.focusInput()
      }

      if(!cancellation_type.show && !cancellation_reason.show && cancellation_note.show) {
        const cancellationNotesRef:any = inst?.refs?.cancellationNotesRef
        cancellationNotesRef.focus()
      }
    }

    const toFirst = () => {
      setTimeout(() => {
        const cancellationTypeRef:any = inst?.refs?.cancellationTypeRef
        cancellationTypeRef.focus()
      }, 1000);
    }

    const closeTime = () => {
      cancelPolicyBodyParam.value.policy_id = null
      cancelPolicyBodyParam.value.cancellation_type = null
      cancellationReason.value.value = null
      cancelPolicyBodyParam.value.cancellation_reason = null
      cancelPolicyBodyParam.value.cancellation_note = null
      cancelPolicyBodyParam.value.documents = []
      reqCancellationRules.value.cancellation_type.show = false
      reqCancellationRules.value.cancellation_reason.show = false
      reqCancellationRules.value.cancellation_note.show = false
    }
    
    const cancelButton =ref()
    const closeModal = (e) => {
      if(e.which === 27) {
        cancelButton.value.click()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      closeTime,
      cancelButton,
      toFirst,
      focusField,
      buttonLoading,
      userObj,
      cancelPolicyReqModalRef,
      policyDetails,
      cancelPolicyBodyParam,
      cancellationReason,
      onChangeCancellationReason,
      cancelPolicy,
      showDomLoad,
      disableSaveButton,
      reqCancellationRules,
    };
  },
});
